import { LoopService } from './../../services/loop-service';
import { Models } from 'models/core';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { RopeService } from 'services/rope-service';
import { RopeTypeService } from 'services/rope-type-service';
import { autoinject, LogManager } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { ErrorService } from 'services/error-service';
import { I18N } from 'aurelia-i18n';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { ValidationController } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Logger } from 'aurelia-logging';
import { NetService } from 'services/net-service';
import { SimpleOfferTabBase } from './simple-offer-tab-base';
import { NetOfferService } from 'services/net-offer-service';
import { ConnectionService } from 'services';
import { Connection } from 'models/Connections';

const logger: Logger = LogManager.getLogger('simple-offer-tab-rope');

@autoinject
export class SimpleOfferTabRope extends SimpleOfferTabBase {
  private netId: number;
  private currentRopes: any;
  private recommendedRopes: Models.Rope[];
  private ropeTypes: Models.RopeType[];
  private nextTabIndex: number = null;
  private formIsValid = true;
  private locked = false;
  protected netStandard: Models.NetStandard;
  private connectionsTitles: {[key: number]: string} = {};

  loops: Array<Models.Loop>;
  connections: Array<Connection>;
  originalObject: unknown;
  loopNewFormVisible = false;
  loopEditFormVisible: number = null;
  isCombiNet = false;

  private subscriptionLoopEditClose: Subscription;
  private subscriptionLoopReset: Subscription;
  private changeTabSubscription: Subscription;

  constructor(
    private netService: NetService,
    private dialogService: DialogService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private ropeService: RopeService,
    private loopService: LoopService,
    private ropeTypeService: RopeTypeService,
    private toastService: ToastService,
    private validationController: ValidationController,
    private utility: Utility,
    private connectionService: ConnectionService,
    errorService: ErrorService,
    netOfferService: NetOfferService
  ) {
    super(netOfferService, errorService);
  }

  private async getRopeTypes(currentRopeTypeId: number) {
    const ropeTypes = await this.ropeTypeService.getAllCached();
    this.ropeTypes = ropeTypes;
    const filteredTypes = ropeTypes.filter(
      (x) =>
        x.Id === this.currentRopes.TopRope?.RopeTypeId ||
        x.Id === this.currentRopes.WaterlineRope?.RopeTypeId ||
        x.Id === this.currentRopes.VerticalRope?.RopeTypeId ||
        x.Id === this.currentRopes.BottomRope?.RopeTypeId ||
        x.Id === this.currentRopes.CrossRope?.RopeTypeId ||
        x.Id === this.currentRopes.CrossRopeInnerBottom?.RopeTypeId ||
        this.currentRopes.ExtraHorizontalRopes?.some((rope: Models.Rope) => rope.RopeTypeId === x.Id) === true ||
        (x.NavisionProduct &&
          (!this.currentRopes.MinBreakingStrength ||
            x.NavisionProduct.MinBreakingStrength >= this.currentRopes.MinBreakingStrength))
    );

    return filteredTypes;
  }

  async activate(params: { NetId: number; Id: number }) {
    this.netId = params.NetId;
    await this.setOfferIsOrder(params.Id);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.changeTabSubscription = this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });

    // Update loops array when creating new loops
    this.subscriptionLoopReset = this.eventAggregator.subscribe('loopListReset', () => {
      this.loopEditFormVisible = null;
      void this.getLoops(this.netId);
      this.eventAggregator.publish('offer-refresh', 'price');
    });

    this.subscriptionLoopEditClose = this.eventAggregator.subscribe('loopFormEditClose', () => {
      return this.getLoops(this.netId);
    });

    this.eventAggregator.subscribe('loopFormNewClose', () => {
      this.loopNewFormVisible = false;
    });

    void this.getNetAndSetLockedAndCombinetFlag(this.netId);
    void this.getLoops(this.netId);
    this.getRopes();
  }

  /**
   * Gets the flag for the current net, and set local property
   * to true/false depending on the net is combi net or not.
   */
  private async getNetAndSetLockedAndCombinetFlag(netId: number) {
    const net = await this.netService.get(`${netId}?$expand=NetDimension($expand=NetStandard)`);
    this.netStandard = net?.NetDimension.NetStandard;
    this.isCombiNet = net?.NetDimension.IsCombiNet ?? false;
    this.locked = net.Locked || this.netIsLinkedToAnalysis(net.NetDimension) || this.offerIsOrder ? true : false;
  }

  private getRopes() {
    Promise.all([this.ropeService.getCurrentRopes(this.netId), this.ropeService.getSuggestedRopeTypes(this.netId)])
      .then((responses) => {
        this.currentRopes = responses[0];
        this.recommendedRopes = responses[1];

        this.currentRopes.ExtraHorizontalRopes?.forEach((rope) => {
          rope.RopeTypeId = rope.RopeType?.Id;
        });

        this.updateOriginalObject();

        const keys = Object.keys(this.currentRopes);

        // set recommended values if no values are set
        keys.forEach((key) => {
          if (
            key === 'MinBreakingStrength' ||
            key === 'ExtraHorizontalRopes' ||
            key === 'LoopRingSetupId' ||
            key === 'CrossRopeInnerBottom'
          ) {
            // ignore this key
          } else {
            if (this.currentRopes[key] || this.recommendedRopes[key]) {
              if (!this.currentRopes[key]) {
                this.currentRopes[key] = this.recommendedRopes[key];
              } else if (!this.currentRopes[key].Amount) {
                this.currentRopes[key].Amount = this.recommendedRopes[key].Amount;
              }

              if (!this.currentRopes[key].RopeType && this.recommendedRopes[key].RopeType) {
                this.currentRopes[key].RopeTypeId = this.recommendedRopes[key].RopeType?.Id;
                this.currentRopes[key].RopeType = this.recommendedRopes[key].RopeType;
              } else if (this.currentRopes[key].RopeType && !this.currentRopes[key].RopeTypeId) {
                this.currentRopes[key].RopeTypeId = this.currentRopes[key].RopeType.Id;
              }
            }
          }
        });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private detached() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
  }

  private deactivate() {
    if (this.changeTabSubscription) {
      this.changeTabSubscription.dispose();
    }
    if (this.subscriptionLoopEditClose) {
      this.subscriptionLoopEditClose.dispose();
    }
    if (this.subscriptionLoopReset) {
      this.subscriptionLoopReset.dispose();
    }
  }

  private deleteRope(rope) {
    this.currentRopes.ExtraHorizontalRopes = this.currentRopes.ExtraHorizontalRopes.filter((x) => x != rope);
  }

  private ropeTypeChanged(event, ropeDetails, isFormCombinetSmallFish = false) {
    if (!event.detail.value) {
      return;
    }

    const ropeTypeId = +event.detail.value;
    const ropeType = this.ropeTypes.find((x) => x.Id === ropeTypeId);

    if (isFormCombinetSmallFish) {
      ropeDetails.IsForCombiNetSmallFish = isFormCombinetSmallFish;
    }

    if (ropeDetails === null) {
      this.toastService.showError('ropes.cannotbeupdated');
    } else {
      ropeDetails.RopeTypeId = ropeTypeId;
      ropeDetails.RopeType = ropeType;
      if (ropeType && ropeType.NavisionProduct) {
        ropeDetails.ActualBreakingStrength = ropeType.NavisionProduct.MinBreakingStrength;
      } else {
        ropeDetails.ActualBreakingStrength = null;
      }
    }
  }

  private addExtraHorizontalRope() {
    this.currentRopes.ExtraHorizontalRopes.map((x) => x.ExtraRopeNumber);
    const maxRopeNumber =
      this.currentRopes.ExtraHorizontalRopes.length > 0
        ? Math.max.apply(
            Math,
            this.currentRopes.ExtraHorizontalRopes.map((x) => x.ExtraRopeNumber)
          )
        : 0;

    this.currentRopes.ExtraHorizontalRopes.push({
      RopeTypeId: this.currentRopes.WaterlineRope?.RopeType?.Id,
      RopeType: this.currentRopes.WaterlineRope?.RopeType,
      Amount: 1,
      ActualBreakingStrength: this.currentRopes.WaterlineRope?.ActualBreakingStrength,
      ExtraRopeNumber: (maxRopeNumber || 0) + 1,
    });
  }

  private addValidationRules() {
    /*     ValidationRules.ensure("NetTypeId")
      .required()
      .withMessage(this.i18n.tr("general.requiredField"))
      .on(this.net); */
  }

  private validateForm() {
    this.validationController.validate().then((result) => {
      if (result.valid) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    });
  }

  private async validateAsync(): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      this.validationController
        .validate()
        .then((result) => {
          if (result.valid) {
            this.formIsValid = true;
            resolve();
          } else {
            this.formIsValid = false;
            reject();
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private updateOriginalObject() {
    if (!this.currentRopes) {
      return;
    }
    this.originalObject = JSON.parse(JSON.stringify(this.currentRopes));
  }

  async saveRopes() {
    await this.save();

    this.eventAggregator.publish('loopListReset');
    this.getRopes();
  }

  private async save() {
    try {
      logger.debug('ropes', this.currentRopes);
      await this.ropeService.saveRopesForNet(this.netId, this.currentRopes);
      this.toastService.showSuccess('ropes.updated');
      this.updateOriginalObject();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private changeTab(direction = 'next') {
    if (direction === 'prev') {
      this.eventAggregator.publish('triggerPrevTab', true);
    } else {
      this.eventAggregator.publish('triggerNextTab', true);
    }
  }

  async saveAndChangeTab(direction = 'next') {
    try {
      // does it validate ?
      await this.validateAsync();
      // check if different
      if (!this.utility.areEqual(this.currentRopes, this.originalObject, true)) {
        // save
        await this.save();
        this.changeTab(direction);
      } else {
        this.changeTab(direction);
      }
    } catch (error) {
      this.toastService.showWarning('general.checkValidationErrors');
    }
  }

  canDeactivate() {
    this.validateForm();

    if (!this.formIsValid) {
      this.toastService.showWarning('general.checkValidationErrors');
      return false;
    }

    if (this.originalObject && !this.utility.areEqual(this.currentRopes, this.originalObject, true) && !this.locked) {
      // tslint:disable-next-line:no-console
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: {
            header: 'dialog.pleaseConfirmHeader',
            message: 'dialog.unsavedChangesText',
          },
        })
        .whenClosed(async (response) => {
          try {
            if (response.wasCancelled) {
              return false;
            } else {
              const result = response.output;
              if (result === 'save') {
                // save the nettingtype and let that function handle the rest of the logic
                await this.save();
                return false;
              }

              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          } catch (error) {
            return false;
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private async getLoops(netId: number) {
    try {
      // TODO: Needs to be like this when Centre bottom screen is OK: .getAll('?$expand=LoopPositionHorizontal,LoopType,LoopPositionVertical&$filter=LoopPositionHorizontalId ne 6 and NetId eq ' + netId)
      const loops = await this.loopService.getAll(
        '?$expand=LoopPositionHorizontal,LoopType,LoopPositionVertical,Connections&$filter=NetId eq ' + netId
      );
      this.loops = loops;
      await this.getConnections(netId)
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getConnections(netId: number) {
    try {
      const connections = await this.connectionService.getAll('?$expand=Coupling&$filter=Netid eq ' + netId)
      this.connections = connections;

      //Have to this because we cant expand Coupling in Connections in the getLoops call
      this.loops.forEach(loop => {
        this.connectionsTitles[loop.Id] = this.connections.find(c => c.LoopId === loop.Id)?.Coupling?.Title;
      })
    } catch (error) {
      this.errorService.handleError(error)
    }
  }

  async deleteLoop(id: number) {
    try {
      const loop = this.loops.find((l) => l.Id == id);

      for (const connection of loop.Connections) {
        await this.connectionService.delete(connection.Id);
      }

      await this.loopService.delete(id);
      this.toastService.showSuccess('loop.deleted');

      await this.getLoops(this.netId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
